import { lazy, Suspense as ReactSuspense } from "react";
import Loader from "../components/website/Loader";

const Loadble = (Component: any) => (props: any) => {
  setTimeout(() => {
    if ((window as any).mainScript) (window as any).mainScript();
  }, 100);

  return (
    <ReactSuspense fallback={<Loader />}>
      <Component {...props} />
    </ReactSuspense>
  );
};

const LazyLoaderMap = {
  Error404: Loadble(lazy(() => import("../pages/website/Error404"))),
  HomePage: Loadble(lazy(() => import("../pages/website/Home"))),
  AboutPage: Loadble(lazy(() => import("../pages/website/About"))),
  ContactPage: Loadble(lazy(() => import("../pages/website/Contact"))),
  TourPage: Loadble(lazy(() => import("../pages/website/Tour"))),
  HotelPage: Loadble(lazy(() => import("../pages/website/Hotels/"))),
  TicketingPage: Loadble(lazy(() => import("../pages/website/Ticketing"))),
  MakeInquiryPage: Loadble(lazy(() => import("../pages/website/MakeInquiry"))),
};

const Routes = [
  {
    path: "",
    element: <LazyLoaderMap.HomePage />,
  },
  {
    path: "about",
    element: <LazyLoaderMap.AboutPage />,
  },
  {
    path: "contact",
    element: <LazyLoaderMap.ContactPage />,
  },
  {
    path: "tours",
    element: <LazyLoaderMap.TourPage />,
  },
  {
    path: "hotels",
    element: <LazyLoaderMap.HotelPage />,
  },
  {
    path: "ticketing",
    element: <LazyLoaderMap.TicketingPage />,
  },
  {
    path: "make-inquiry",
    element: <LazyLoaderMap.MakeInquiryPage />,
  },
  {
    path: "*",
    element: <LazyLoaderMap.Error404 />,
  },
];

export default Routes;
