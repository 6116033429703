import { WebsiteLayout } from "../components/website/layout";
import WebsiteRouter from "./WebsiteRouter";


const Routes: any = [
  {
    path: "/",
    element: <WebsiteLayout />,
    children: [...WebsiteRouter],
  },
];

export default Routes;
