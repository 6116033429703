import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { CopyRight } from "./CopyRight";
import Wrapper from "../Wrapper";

const WebsiteLayout = ({ children }: any) => {
  return (
    <Wrapper reload={true}>
      <div id="travel_wrapper">
        <Header />
        <>{children || <Outlet />}</>
        <Footer />
        <CopyRight />
      </div>
    </Wrapper>
  );
};

export { WebsiteLayout };
