export const CopyRight = () => {
  return (
    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            {" "}
            <span>Stay Connected with Us - </span>{" "}
            <a href="">
              <i className="fa fa-facebook"></i>
            </a>{" "}
            <a href="">
              <i className="fa fa-twitter"></i>
            </a>{" "}
            <a href="">
              <i className="fa fa-instagram"></i>
            </a>{" "}
            <a href="">
              <i className="fa fa-pinterest"></i>
            </a>{" "}
          </div>
          <div className="col-md-6 col-sm-6 text-right">
            {" "}
            <span>
              WayTravel@{new Date().getFullYear()}. All Right Reserved
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
