/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export const Banner = () => {
  return (
    <div className="header_top">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-8">
            <p>
              <span>
                <i className="fa fa-phone"></i> For Support? Call Us:{" "}
                <span>+256 702 300509</span>
              </span>

              <span style={{ marginLeft: "1rem" }}>
                <i
                  className="fa fa-envelope"
                  style={{ marginRight: "0.5rem" }}
                ></i>
                <span>waytravels16@gmail.com</span>
              </span>
            </p>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="top_menu">
              <ul>
                <li>
                  <a href="">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fa fa-pinterest-square"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
