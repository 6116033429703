import React from "react";

function Wrapper(props: any) {
  const loadMainScript = () => {
    setTimeout(() => {
      (window as any).mainScript && (window as any).mainScript(props.reload);
    }, 100);
  };
  return (
    <>
      {props.children}
      {loadMainScript()}
    </>
  );
}

export default Wrapper;
