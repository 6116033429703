/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
import { Banner } from "./Banner";
import { useState } from "react";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleNavigation = (path: string, query?: any) => {
    navigate(path, { state: query });
  };

  const isActiveLink = (link: string) => {
    const { pathname } = location;
    const path = pathname.split("/")[1];
    return link === path;
  };

  const handleNavigationWithQueryParams = (path: string, query: any) => {
    const searchParams = new URLSearchParams();
    Object.keys(query).forEach((key) => {
      searchParams.append(key, query[key]);
    });
    navigate(`${path}?${searchParams.toString()}`);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <header id="header_wrapper">
      <Banner />

      <div className="header_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-2">
              <div className="travel_logo">
                <a href="javascript:;" onClick={() => handleNavigation("/")}>
                  <img
                    src="assets/img/logo/normal-logo.png"
                    width={180}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-10 col-sm-10">
              {" "}
              <a
                href="javascript:;"
                onClick={toggleMobileMenu}
                className={
                  !showMobileMenu
                    ? "menu-toggle"
                    : "menu-toggle menu-toggle--open"
                }
              ></a>
              <div className={!showMobileMenu ? "main_menu" : "main_menu open"}>
                <ul>
                  <li className={isActiveLink("") ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={() => handleNavigation("/")}
                    >
                      home
                    </a>
                  </li>
                  <li className={isActiveLink("tours") ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={() => handleNavigation("/tours")}
                    >
                      tours
                    </a>
                  </li>
                  <li className={isActiveLink("hotels") ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={() => handleNavigation("/hotels")}
                    >
                      hotels
                    </a>
                  </li>
                  <li className={isActiveLink("ticketing") ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={() => handleNavigation("/ticketing")}
                    >
                      Ticketing
                    </a>
                  </li>
                  <li className={isActiveLink("car-hire") ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={() => handleNavigation("/car-hire")}
                    >
                      Care Hire
                    </a>
                  </li>
                  <li className={isActiveLink("contact") ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={() => handleNavigation("/contact")}
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className={isActiveLink("about") ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={() => handleNavigation("/about")}
                    >
                      About
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
